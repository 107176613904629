@import '../../assets/styles/variables';
.wrap {
  margin: 50px auto 0;

  @media screen and (min-width: $mobile-landscape-min) and (orientation: landscape) {
    margin-top: 70px;
    max-width: 655px;
  }

  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    margin-top: 50px;
    max-width: 437px;
  }
  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    margin-top: 50px;
    max-width: 940px;
  }

  @media screen and (max-width: 850px) and (orientation: landscape) {
    margin: 30px auto 0;
  }

  @media screen and (max-height: 650px) and (orientation: portrait) {
    margin: auto;
  }
}

.container {
  width: 100%;
  overflow: hidden;
  padding: 0 16px;
}

.autoplay-settings {
  display: flex;
  width: 100%;
  margin: auto;

  @media screen and (min-width: $tablet-landscape-min) and (max-width: 1180px) and (orientation: landscape) {
    scale: 0.8;
  }
  @media screen and (max-width: 850px) and (orientation: landscape) {
    scale: 0.7;
  }

  @media screen and (max-height: 650px) and (orientation: portrait) {
    scale: 0.7;
  }
  
  &__item {
    display: flex;
    margin-bottom: 16px;
    height: auto;

    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      height: 112px;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      height: 112px;
    } 

    &.btns {
      display: block;

      .label {
        margin-bottom: 11px;
      }
    }
  }

  &__col {
    width: 100%;
    margin: 0;
    @media screen and (orientation: landscape) {
      width: 50%;
      &:nth-child(1) {
        margin-right: 24px;
      }
      &:nth-child(2) {
        margin-left: 24px;
      }
    }
    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      width: 50%;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      width: 50%;
    }
  }

  .controls {
    display: flex;
    align-items: center;    
  }

  .toggle {
    margin-right: 8px; 
    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      margin-right: 22px;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      margin-right: 22px;
    }
  }

  .slider {
    width: 230px;
    margin-top: 4px;
    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      width: 290px;
      margin-top: 8px;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      width: 290px;
      margin-top: 8px;
    }
  }

  @media only screen and (orientation: portrait) {
    flex-wrap: wrap;
    &__col {
      width: 100%;
      margin: 0;
    }
  }
}

.autoplay-settings::-webkit-scrollbar {
  display: none;
}

.label {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  white-space: nowrap;

  .value {
    display: block;
    color: var(--game-color);
  }

  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 26px;
  }
  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 26px;
  }
}

.input {
  font-family: var(--font-family);
  padding: 1px 2px;
  letter-spacing: 0px;
}